import React from "react"
import "../styles/long1.css"
import "../styles/mysterybox.css"
import wheel from "../assets/WHEEL.png"
import { Helmet } from "react-helmet"

const PromoEndedRedirect = () => {
  return <head>
    <meta http-equiv="refresh" content="1; url='/mysterybox-ended'" />
  </head>
}

const MbSection2 = (props) => {
  return (
    <div className="mb-section-3">
      {props.children}
      <div className={"left-third" + " " + props.padLeftThird}>
        <div className="redline-img"></div>
        <p className="">
          {props.leftText}
        </p>
      </div>
      <div className="right-two-thirds">
        <p className="">
          {props.rightText}
        </p>
      </div>
    </div>
  )
}

const MbTerms = (props) => {

  return (
    <div className={"mb-terms" + " " + props.class}>
      <br />
      <br />
      Entries only valid through Monday, November 28, 2022 at 9:00am CST.
      Discount offer only valid on our LBJ/NTE/NTE 35W TEXpress Lanes from
      December 1, 2022 at 9:00am CST to January 31, 2023 at 11:59pm CST. For
      full Terms & Conditions,
      {/* TODO: change T&C URL */}
      <a
        href="https://www.texpresslanes.com/lbj-nte-nte-35w-texpress-lanes-mystery-toll-discount-promotion-official-rules/"
        target={"_blank"}
      >
         please click here
      </a>
      .{" "}
    </div>
  )
}

const IndexPage = () => (
  <div className="grid-container mysterybox">
    <Helmet>
      {/* <!--START Lightbox Javascript--> */}
      {/* <script async type='text/javascript' src='https://www.lightboxcdn.com/vendor/8d9917f0-0003-4ba7-baf5-0fb1897cc287/lightbox_inline.js'></script> */}
    </Helmet>

    <div className="header">
      <div className="header1">
        <div className="logo-div go-logo-div">
          <a aria-current="page" className="logo" href="/">
            <div className="driverprofile-logo"></div>
          </a>
        </div>
      </div>
      <a href="https://www.texpresslanes.com/" target="_blank">
        <div className="header2">What are TEXpress Lanes</div>
      </a>
      <a
        href="https://www.texpresslanes.com/maps/texpress-lanes-map"
        target="_blank"
      >
        <div className="header3">Where are TEXpress Lanes</div>
      </a>
      <a
        href="https://www.texpresslanes.com/navigating-texpress-lanes/how-to-use"
        target="_blank"
      >
        <div className="header4">How to Use TEXpress Lanes</div>
      </a>
    </div>
    <div className="left_space"></div>
    <div className="short1-main">
      <div className="mb-bg">
        <div className="mb-headSection max-width-contain">
          <div className="mb-leftSection">
            <p className="mb-header">
              Spin To Reveal<br></br>Your Mystery Toll Discount!
            </p>
            <p className="mb-subtext">
              You could get $$$ off each of your tolls on the LBJ, NTE 7, & 35W
              TEXpress Lanes starting December 1st!
              <br />
              <br />
              You’re on the Road to BIG Discounts
            </p>
            <div className="wheel-bg"></div>
            {/* <img src={wheel} /> */}
          </div>
          <div className="mb-rightSection">
            <div
              id="lightbox-inline-form-6fa358ec-0aa4-4983-8b0c-25ac62bd84f0"
              className="mbpage-form"
            ></div>
            <MbTerms class="show-in-desktop" />
          </div>
        </div>
      </div>
      <div className="mbgogo-main2 max-width-contain margin-top400">
        <MbSection2
          leftText="What Are The LBJ/NTE/NTE 35W TEXpress Lanes?"
          rightText="The LBJ/NTE/NTE 35W TEXpress Lanes are unique toll lanes built
        within existing highways to accommodate more traffic volume.
        Unlike traditional toll roads, the price changes based on
        real-time traffic conditions in order to reduce congestion and
        keep everyone moving at least 50 MPH."
          padLeftThird="pad-top"
        >
          <MbTerms class="show-in-mobile" />
        </MbSection2>
      </div>

      <div className="mbgogo-main2 max-width-contain">
        <MbSection2
          leftText="How Can I Use the LBJ/NTE/NTE 35W TEXpress Lanes?"
          rightText="Got a TollTag, TxTag or EZ TAG? You're ready to use the
        LBJ/NTE/NTE 35W TEXpress Lanes and enter the sweepstakes
        promotion!"
        />
      </div>
      <div className="mbgogo-main4">
        <div className="mb-main4-padding">
          <p className="mb-main4-header">
            Where Can I Use The LBJ/NTE/NTE35W <br />
            TEXpress Lanes?
          </p>
          <p className="mb-small-text">
            LBJ Express (I-635) &amp; North Tarrant Express (I-820, SH 183 and
            SH 121I-35W)
          </p>
          <p className="mb-xsmall-text">
            Sweepstakes promotion valid only on our lanes highlighted blue on
            the map below:
          </p>
          <div className="map-img"></div>
          <a className="mb-linkStyle mb-button" href="#">
            <span className="mb-button">Enter Now</span>
            {/* <p className="mb-button">Enter Now</p> */}
          </a>
        </div>
      </div>
      <div className="mb-main4">
        <div className="register-box">
          <p id="register-box-header" className="register-box-text">
            Need help finding your toll transponder number?
          </p>
          <p className="register-box-text">
            Here are two ways you can locate your toll transponder number:
          </p>
          <div className="register-bot-img"></div>
          <p className="register-box-text-small">
            Refer to the images above to find the information printed on your
            toll tag transponder on your vehicle's windshield.<br></br>
            The toll transponder number is printed on the side facing the driver
            in the vehicle.
          </p>
          <br />
          <p className="register-box-text-small">
            You can also find your toll transponder number by logging into your
            respective toll transponder account.
          </p>
          <div className="register-box-link">
            <a href="https://csc.ntta.org/olcsc/" target="_blank">
              Visit My TollTag Account
            </a>{" "}
            <span className="mobile-line">|</span>{" "}
            <a href="https://www.txtag.org/en/home/index.shtml" target="_blank">
              Visit My TxTag Account
            </a>{" "}
            <span className="mobile-line">|</span>{" "}
            <a href="https://www.hctra.org/Login" target="_blank">
              Visit My EZ TAG Account
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className="right_space"></div>
    <div className="footer">
      <div className="register-footer1">
        <a href="https://www.facebook.com/TEXpressLanes" target="_blank">
          <img
            className="fb-icon"
            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
          />
        </a>{" "}
        |{" "}
        <a href="https://twitter.com/texpresslanes" target="_blank">
          <img
            class="twitter-icon"
            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
          />
        </a>{" "}
        |{" "}
        <a href="https://www.texpresslanes.com/privacy-policy" target="_blank">
          Privacy Policy
        </a>{" "}
        │ ©2022 LBJ/NTE/NTE 35W TEXpress Lanes | All Rights Reserved
      </div>
    </div>
  </div>
)

export default IndexPage;
